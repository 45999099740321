declare global {
  interface Window {
    desktop?: {
      platform: string;
      loadURL: (url: string) => void;
      openExternalURL: (url: string) => void;
      addTab: (url?: string) => void;
      addInitialTab?: (url?: string) => void;
      closeTab: (index: number) => void;
      forwardInTab: (index: number) => void;
      backInTab: (index: number) => void;
      focusTab: (index: number) => void;
      onTabChange: (handler: (state: TabBarState) => void) => void;
      openHelp: (opened: boolean) => void;
      toggleHelp: () => void;
      onToggleHelp: (handler: (opened?: boolean) => void) => void;
      moveTab?: (sourceIndex: number, destinationIndex: number) => void;
    };
  }
}

type TabItem = { id: number; title: string; icon: string };

type TabBarState = {
  tabs: TabItem[];
  focusedTabId: number;
  canGoForward: boolean;
  canGoBack: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const isElectron = Boolean(
  typeof window !== 'undefined' && window.desktop,
);

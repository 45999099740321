const wrapTrack = <Args extends Array<unknown>>(
  track: unknown,
  wrapped?: (...args: Args) => unknown,
) => {
  return (...args: Args) => {
    if (track) {
      window.analytics.track('event_click', track);
    }
    wrapped?.(...args);
  };
};

export default wrapTrack;

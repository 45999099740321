import { navigate } from 'gatsby';
import React, { SyntheticEvent, useCallback, useLayoutEffect } from 'react';
import hero from '../../images/home/hero.png';
import logoWhite from '../../images/logo-white.svg';
import mark from '../../images/mark.svg';
import { isElectron } from '../../utils/desktopClient';
import LoggedInTeams from '../../utils/LoggedInTeams';
import UntitledButton from '../ui/UntitledButton';
import Layout from './Layout';
import UntitledStyle from './UntitledStyle';
import styles from './WelcomeLayout.module.scss';
import SITE_URL from '../../utils/siteUrl';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
const SLAB_LOGIN_URL = `${SITE_URL}/app/desktop/login`;

const WelcomeLayout = () => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loggedInHosts = LoggedInTeams.getHosts();
  const handleSignIn = useCallback((event: SyntheticEvent) => {
    if (isElectron) {
      event.preventDefault();

      window.desktop?.openExternalURL(SLAB_LOGIN_URL);
    } else {
      window.open(SLAB_LOGIN_URL, '_blank');
    }
  }, []);

  useLayoutEffect(() => {
    if (loggedInHosts.length > 0) {
      navigate('/desktop/tabbar');
    }
  }, [loggedInHosts]);

  if (loggedInHosts.length) {
    return null;
  }

  return (
    <Layout>
      <UntitledStyle>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <img
              className={styles.logoMark}
              src={mark}
              alt=""
              role="presentation"
            />
            <img className={styles.logoText} src={logoWhite} alt="Slab" />
          </div>

          <div className={styles.titleContainer}>
            <h1>Welcome to Slab</h1>
          </div>

          <div className={styles.labelContainer}>
            <p>
              We&apos;ll take you to your web browser and bring you back here.
            </p>
          </div>

          <UntitledButton
            variant="primary"
            large
            onClick={handleSignIn}
            to={SLAB_LOGIN_URL}
          >
            Sign in to Slab
          </UntitledButton>

          <div className={styles.heroContentContainer}>
            <img alt="" role="presentation" src={hero} />
          </div>
        </div>
      </UntitledStyle>
    </Layout>
  );
};

export default WelcomeLayout;
